.command-line, .command-line input{
    background: #000;
    color: #fff;
    font-family: inherit;
    font-size: inherit;
}

.command-line input{
    border: none;
}

.command-line span::before{
    color: #fff;
    content: '$ ';
}

.command-line input:focus{
    outline: none;
}
